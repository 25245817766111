<template>
  <div class="mt-1 mx-2">
    <b-card>
      <div class="mb-2">
        <h3 class="text-primary font-weight-bolder">
          <feather-icon icon="SettingsIcon" size="20" /> Configuración Creación de Retiros
        </h3>
        <span>
          Desde esta vista se puede determinar a que sellers ocultar la acción
          de crear retiros.
        </span>
      </div>
      <!-- Formulario -->
      <b-skeleton v-show="loading.first" type="input" class="mt-2 mb-2 spacing-label-field" height="40px"/>
      <form-render
        v-show="!loading.first"
        ref="disableCompaniesForm"
        class="mb-2"
        @send="filterList"
        :form="formfilter"
        :fields="fields"
        :buttonSend="buttonSend" 
        containerButtonsClass="col-sm-12 col-md-8 col-lg-4 container-button mt-2"
      >
        <template #buttons>
          <b-button variant="outline-light" v-b-tooltip.hover :title="$t('Limpiar filtros')" class="ml-2" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>  
        </template>
      </form-render>

      <!-- <form-render v-show="!loading.first" class="px-0 mt-2 mb-2" :fields="fieldsType" :form.sync="selectedRadioOption"></form-render> -->
      
      <!-- Acciones masivas -->
      <div v-show="!loading.first">
        <span v-b-tooltip="disabledPanel ? $t('msg-alert-disabled-panel') : ''">
          <b-button :disabled="disabledPanel" @click="openModalEditMassiveShippers" variant="outline-light" class=""><feather-icon icon="EditIcon"/> {{ $t('msg-btn-edit-massive-status') }} </b-button> 
        </span>
        <b-button @click="downloadExcel" :disabled="loading.excel" class="push-right" variant="outline-success">
          <feather-icon v-if="!loading.excel" icon="DownloadIcon"/> <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/>  {{$t('Exportar a excel')}} 
        </b-button>
      </div>

      <table-render 
        id="listDeriveries" 
        v-if="!loading.first" 
        :showCheckboxes="true" 
        :selectedRows.sync="selectedRows" 
        :loading="loading.companies" 
        class="mt-2" 
        :schema="schema" 
        :rows="rows" 
        :actions="actions">
          <template #shipper_status="scope">
            <span class="table_dot--state" :class="[scope.rowdata.shipper_status ? 'table_dot--status-block-true' : 'table_dot--status-block-false']"></span>
            <span class="ml-1"> {{ scope.rowdata.shipper_status ? 'Bloqueado' : 'Habilitado' }} </span>
          </template>
      </table-render>
      <pagination v-if="!loading.first" :pagination="pagination" :noDigits="false" :showSize="true"/>
      <div v-if="loading.first" class="table-render-skeleton">
        <b-skeleton-table
          :rows="10"
          :columns="10"
          :table-props="{ }"/>
      </div>
    </b-card>
    <modal-edit-massive-status-shippers ref="modalMassiveEditShippers" :selectedShippers="selectedShippers" @confirmMassiveEditShippers="confirmEditMassiveShippers"></modal-edit-massive-status-shippers>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfigPickupService from '../config-pickups.service'
import ModalEditMassiveStatusShippers from './ModalEditMassiveStatusShippers.vue'
import * as moment from 'moment'

export default {
  components: { ModalEditMassiveStatusShippers },
  data() {
    return {
      loading: {
        first: true,
        companies: false,
        excel: false
      },
      formfilter: {},
      filters: {},
      fields: [],
      selectedShippers: [],
      listCount: {
        activated: 0,
        deactivated: 0
      },
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      schema: [],
      rows: [],
      selectedRows: [],
      disabledPanel: true,
      selectedCheck: 1,
      pagination: {
        page: 1,
        total: 40,
        limit: 20
      },
      ROLES : {
        marketplace: 'marketplace',
        seller: 'seller',
        admin: 'admin'
      },
      statusOptions: [
        {
          text: 'Empresas habilitadas',
          value: false,
          id: 1
        },
        {
          text: 'Empresas deshabilitadas',
          value: true,
          id: 2
        },
        {
          text: 'Todas las empresas',
          value: 'all',
          id: 3
        }
      ],
      forbiddenRoute: 'forbidden',
      configPickupService: new ConfigPickupService(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  mounted () {
    this.getAllInitialData()
  },
  watch: {
    mySession () {
      // if (this.mySession.id) this.setInitialData()
      if (this.mySession.organization.id && this.mySession.role === this.ROLES.marketplace) this.setInitialData()
      else this.$router.push({ name: this.forbiddenRoute })
    },
    selectedRows () {
      if (this.selectedRows.length === 0) this.disabledPanel = true
      else this.disabledPanel = false
    },
    'pagination.page' () {
      this.getShippers()
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.getShippers()
    }
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldInput', 
          name: 'shipper', 
          label: 'Seller', 
          clearable: true, 
          containerClass: 'col-sm-12 col-md-6 col-lg-3'
        },
        {
          fieldType: 'FieldSelect', 
          name: 'status', 
          label: 'Estado de la empresa', 
          clearable: false, 
          containerClass: 'col-sm-12 col-md-6 col-lg-2',
          options: this.statusOptions
        }
      ]
      this.schema = [
        { label: 'ID', key: 'id', sortable: true, class: ['w-10'] },
        { label: 'Seller', key: 'name', sortable: true },
        { label: 'Estado', key: 'shipper_status', sortable: true, useSlot: true },
        { label: 'Acciones', sortable: true, key: 'actions', style: {width: '20px'}, class: ['text-center'] }
      ]
      this.actions = [
        { action: id => this.confirmEditShipper(id), 
          color: 'warning', 
          switchOwnId: 'shipper_status',
          dualState: { on: 'Activar', off: 'Desactivar', iconOn: 'EyeIcon', iconOff: 'EyeOffIcon' } 
        }
      ]
      this.getShippers()
    },
    // Función para confirmar la edicion de una empresa
    confirmEditShipper (shipper_id) {
      const selectedShipperStatus = this.rows.filter(shipper => shipper.id === shipper_id)[0].shipper_status
      if (selectedShipperStatus) this.$yesno(this.$t('msg-confirm-enable-company'), () => this.editCustomizationShipper([shipper_id], false))
      else this.$yesno(this.$t('msg-confirm-disable-company'), () => this.editCustomizationShipper([shipper_id], true))
    },
    /**
     * Función que abre el modal para edicion massiva de emoresas
     */
    openModalEditMassiveShippers () {
      this.$bvModal.show('modalEditShippers')
      this.selectedShippers = this.rows.filter(shipper => this.selectedRows.includes(shipper.id))
    },
    /**
     * Función para confirmar habilitar/deshabilitar retiros a multiples empresas
     */
    confirmEditMassiveShippers(data) {
      const {status, shipper_ids} = data
      let msgConfirm = ''
      if (status) msgConfirm = 'msg-confirm-disable-multiple-companies'
      else msgConfirm = 'msg-confirm-enable-multiple-companies'
      this.$bvModal.hide('modalEditShippers')
      this.$yesno(this.$t(msgConfirm), () => this.editCustomizationShipper(shipper_ids, status))
    },
    // Funcion request a pucon para editar el customization de una empresa 
    editCustomizationShipper (company_ids, block_pickups) {
      const payload = {}
      company_ids.map(company_id => payload[company_id] = { block_pickups, block_pickups_modification_date: moment().format('YYYY-MM-DD h:mm:ss') })
      this.$refs.modalMassiveEditShippers.buttonSend.disabled = true
      this.configPickupService.callService('editCustomizationShipper', payload, null)
        .then(resp => {
          this.$success(this.$t('msg-success-edit-company'))
        })
        .catch(err => {
          this.$alert(this.$t('msg-error-edit-company', {code: err}))
        })
        .finally(() => {
          this.$refs.modalMassiveEditShippers.buttonSend.disabled = false
          this.getShippers()
        })
    },
    cleanFilter () {
      this.formfilter = {}
      this.filterList(this.formfilter)
    },
    filterList (data) {
      this.filters = {}

      if (data.shipper) this.filters['search'] = data.shipper
      if (data.status) {
        if (data.status.value === 'all') {
          delete this.filters['block_pickups']
        } else {
          this.filters['block_pickups'] = data.status.value
        }
      } 

      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getShippers()
    },
    getShippers () {
      const payload = {
        ...this.filters,
        extra_fields: 'customization',
        sort: 'block_pickups',
        order: 'desc',
        limit: this.pagination.limit, 
        page: this.pagination.page
      }
      this.loading.companies = true
      this.configPickupService.callService('getShippers', payload, {organization_id: this.mySession.organization.id})
        .then(resp => {
          this.pagination.total = resp.meta.total
          this.setDataRows(resp.data)
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading.first = false
          this.loading.companies = false
          this.selectedRows = []
        })
    },
    setDataRows (data) {
      this.rows = data.map(el => ({...el, id: el.id, shipper_status: el.customization?.block_pickups ? el.customization.block_pickups : false}))
    },
    downloadExcel () {
      const payload = {
        marketplace_id: this.mySession.organization.id
      }
      this.loading.excel = true
      this.configPickupService.callService('exportConfigShippers', null, payload)
        .then(resp => {
          if (resp.data) window.open(resp.data, '_blank')
          else this.$alert(this.$t('msg-error-export-excel'))
        })
        .catch(err => {
          this.$alert(this.$t(err))
        })
        .finally(end => {
          this.loading.excel = false
        })
    }
  }
}
</script>

<style>
.table_dot--state {
  position: relative;
  display: inline-block;
  top: 3px;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.table_dot--status-block-true {
  background-color:#ED5666;
}

.table_dot--status-block-false {
  background-color: #55D6A7;
}

</style>