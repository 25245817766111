<template>
  <div>
    <b-modal      
      id="modalEditShippers" 
      size="lg" 
      title="Edición de multiples empresas" 
      hide-footer
      centered>
        <div class="container custom-modal-content">
          <form-render
            ref="EditmassiveShippersForm"
            class="mb-2"
            @send="confirmEdit"
            :form="formfilter"
            :fields="fields"
            :buttonSend="buttonSend"
            containerButtonsClass="col-sm-12 col-md-8 col-lg-4 container-button mt-2">
          </form-render>

          <!-- Mensaje de alerta -->
          <div class="col-md-12">
            <b-alert class="p-1" variant="primary" show>
              <div class="d-flex justify-content-center align-items-center">
                <feather-icon size="30" class="mr-50" icon="InfoIcon"/>
                  <p>
                    Tenga en cuenta que el cambio seleccionado tendrá efecto en todas las empresas marcadas/seleccionadas.
                  </p>
              </div>
            </b-alert>
          </div>

          <div class="col-md-12 mb-3">
            <table-render 
              class="mt-2" 
              :schema="schema" 
              :rows="selectedShippers">
              <template #shipper_status="scope">
                <span class="table_dot--state" :class="[scope.rowdata.shipper_status ? 'table_dot--status-block-true' : 'table_dot--status-block-false']"></span>
                <span class="ml-1"> {{ scope.rowdata.shipper_status ? 'Bloqueado' : 'Habilitado' }} </span>
              </template>
            </table-render>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['selectedShippers'],
  data () {
    return {
      formfilter: {},
      fields: [],
      buttonSend: { text: 'Confirmar edición', color: 'warning' },
      schema: [],
      msgAlertEditShippers: null,
      statusOptions: [
        {
          text: 'Habilitar empresas',
          value: false,
          id: 1
        },
        {
          text: 'Deshabilitar empresas',
          value: true,
          id: 2
        }
      ]
    }
  },
  mounted () {
    this.fields = [        
      {
        fieldType: 'FieldSelect', 
        name: 'status', 
        label: 'Estado a asignar', 
        clearable: true, 
        containerClass: 'col-sm-12 col-md-6 col-lg-6',
        options: this.statusOptions,
        validation: 'required'
      }
    ]
    this.schema = [
      { label: 'ID', key: 'id', sortable: true, class: ['w-50'] },
      { label: 'Seller', key: 'name', sortable: true },
      { label: 'Estado', key: 'shipper_status', sortable: true, useSlot: true }
    ]
  },
  methods: {
    confirmEdit (data) {
      const emitPayload = {
        status: data.status.value,
        shipper_ids: this.selectedShippers.map(shipper => shipper.id)
      }
      this.$emit('confirmMassiveEditShippers', emitPayload)
    }
  }
}
</script>

<style scoped>
.custom-modal-content {
  max-height: 70vh;
  overflow-y: auto;
}
</style>